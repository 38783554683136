import { render, staticRenderFns } from "./RegisterFacilityModal.vue?vue&type=template&id=45a5fd02&scoped=true"
import script from "./RegisterFacilityModal.vue?vue&type=script&lang=js"
export * from "./RegisterFacilityModal.vue?vue&type=script&lang=js"
import style0 from "./RegisterFacilityModal.vue?vue&type=style&index=0&id=45a5fd02&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a5fd02",
  null
  
)

export default component.exports