<template>

  <div>
    <AppBarPage v-if="!loading && notAuthorized">
      <KPageContainer>
        <AuthMessage
          :authorizedRole="authorizedRole"
          :header="authorizationErrorHeader"
          :details="authorizationErrorDetails"
        />
      </KPageContainer>
    </AppBarPage>

    <AppBarPage v-else-if="!loading && error">
      <KPageContainer>
        <AppError />
      </KPageContainer>
    </AppBarPage>

    <div
      v-else
      tabindex="-1"
      data-test="base-page"
    >
      <slot :loading="loading"></slot>
    </div>

    <GlobalSnackbar />
    <UpdateNotification
      v-if="!loading && showNotification && mostRecentNotification"
      :id="mostRecentNotification.id"
      :title="mostRecentNotification.title"
      :msg="mostRecentNotification.msg"
      :linkText="mostRecentNotification.linkText"
      :linkUrl="mostRecentNotification.linkUrl"
      @submit="dismissUpdateModal"
    />
  </div>

</template>


<script>

  import { mapState, mapGetters } from 'vuex';
  import Lockr from 'lockr';
  import { UPDATE_MODAL_DISMISSED } from 'kolibri.coreVue.vuex.constants';
  import { currentLanguage, defaultLanguage } from 'kolibri.utils.i18n';
  import AuthMessage from 'kolibri.coreVue.components.AuthMessage';
  import AppBarPage from 'kolibri.coreVue.components.AppBarPage';
  import AppError from 'kolibri-common/components/AppError';
  import GlobalSnackbar from 'kolibri-common/components/GlobalSnackbar';
  import UpdateNotification from './UpdateNotification.vue';

  export default {
    name: 'NotificationsRoot',
    components: {
      AppBarPage,
      AppError,
      AuthMessage,
      GlobalSnackbar,
      UpdateNotification,
    },
    props: {
      authorized: {
        type: Boolean,
        required: false,
        default: true,
      },
      authorizedRole: {
        type: String,
        default: null,
      },
      authorizationErrorHeader: {
        type: String,
        default: null,
      },
      authorizationErrorDetails: {
        type: String,
        default: null,
      },
      loading: {
        type: Boolean,
        default: null,
      },
    },
    data() {
      return {
        notificationModalShown: true,
      };
    },
    computed: {
      ...mapGetters(['isAdmin', 'isSuperuser']),
      ...mapState({
        error: state => state.core.error,
        notifications: state => state.core.notifications,
      }),
      notAuthorized() {
        // catch "not authorized" error, display AuthMessage
        if (
          this.error &&
          this.error.response &&
          this.error.response.status &&
          this.error.response.status == 403
        ) {
          return true;
        }
        return !this.authorized;
      },
      showNotification() {
        if (
          (this.isAdmin || this.isSuperuser) &&
          !Lockr.get(UPDATE_MODAL_DISMISSED) &&
          this.notificationModalShown &&
          this.notifications.length !== 0
        ) {
          return true;
        }
        return false;
      },
      mostRecentNotification() {
        let languageCode = defaultLanguage.id;
        // notifications should already be ordered by timestamp
        const notification = this.notifications[0];
        if (notification) {
          // check if translated message is available for current language
          if (notification.i18n[currentLanguage] !== undefined) {
            languageCode = currentLanguage;
          }
          // i18n data structure generated by nutritionfacts_i18n.py
          return {
            id: notification.id,
            title: notification.i18n[languageCode].title,
            msg: notification.i18n[languageCode].msg,
            linkText: notification.i18n[languageCode].link_text,
            linkUrl: notification.link_url,
          };
        }
        return null;
      },
    },
    methods: {
      dismissUpdateModal() {
        if (this.notifications.length === 0) {
          this.notificationModalShown = false;
          Lockr.set(UPDATE_MODAL_DISMISSED, true);
        }
      },
    },
  };

</script>


<style lang="scss" scoped></style>
