var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"languages-list"},[_c('KListWithOverflow',{attrs:{"items":_vm.buttonLanguages,"appearanceOverrides":{
        justifyContent: _vm.center ? 'center' : 'flex-start',
        alignItems: 'center',
      }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [(!item.isSelected)?_c('KButton',{staticClass:"lang px-8",attrs:{"text":item.lang_name,"title":item.english_name,"appearance":"basic-link"},on:{"click":function($event){return _vm.switchLanguage(item.id)}}}):_c('SelectedLanguage',{attrs:{"selectedLanguage":item},on:{"click":function($event){_vm.showLanguageModal = true}}})]}},{key:"more",fn:function(ref){
      var overflowItems = ref.overflowItems;
return [_c('div',[(overflowItems.length === _vm.buttonLanguages.length)?_c('SelectedLanguage',{attrs:{"selectedLanguage":_vm.selectedLanguage},on:{"click":function($event){_vm.showLanguageModal = true}}}):_vm._e(),_c('KButton',{staticClass:"px-8",attrs:{"text":_vm.$tr('showMoreLanguagesSelector'),"appearance":"flat-button"},on:{"click":function($event){_vm.showLanguageModal = true}}})],1)]}}])})],1),(_vm.showLanguageModal)?_c('LanguageSwitcherModal',{staticClass:"ta-l",on:{"cancel":function($event){_vm.showLanguageModal = false}}}):_vm._e()],1)}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }