var render, staticRenderFns
import script from "./KeenUiToolbar.vue?vue&type=script&lang=js"
export * from "./KeenUiToolbar.vue?vue&type=script&lang=js"
import style0 from "./KeenUiToolbar.vue?vue&type=style&index=0&id=5c353296&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c353296",
  null
  
)

export default component.exports