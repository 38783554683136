<template>

  <KCircularLoader :delay="false" />

</template>


<script>

  export default {
    name: 'ContentRendererLoading',
  };

</script>
